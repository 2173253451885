@import '../../assets/styles/breakpoints';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 0.8rem;
  font-weight: 300;
  background: var(--color-black);
  color: var(--color-white);

  @include breakpoint(mobile) {
    flex-direction: column-reverse;
  }

  .copyrights {
    width: 60em;
    max-width: 50%;

    @include breakpoint(tablet) {
      width: auto;
      max-width: 65%;
    }

    @include breakpoint(mobile) {
      margin-top: 1em;
      width: auto;
      max-width: 100%;
      text-align: center;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;

    @include breakpoint(mobile) {
      width: 100%;
      justify-content: space-evenly;
    }

    .button {
      display: inline-flex;
      margin: 0 0 0 1em;
      max-width: 30vw;
      font-size: 0.8rem;

      @include breakpoint(mobile) {
        max-width: 100%;
      }
    }
  }
}