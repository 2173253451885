:root {
  --header-height: 20vh;
  --menubar-height: 3.5rem;
  --footer-height: 5em;
  --container-margin-y: 5vw;

  --color-primary: #ee1100;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #34373f;
  --color-light-gray: #717272;
}
