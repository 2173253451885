/**
 * The responsive behavior is Desktop-first.
 */

$min-widths: (
  mobile: 0,
  tablet: 768px,
  laptop: 1025px,
  desktop: 1441px,
);

$max-widths: (
  mobile: 767px,
  tablet: 1024px,
  laptop: 1440px,
  desktop: 32000px,
);

@function breakpoint-min($key) {
  @if (map-has-key($min-widths, $key)) {
    @return map-get($min-widths, $key);
  }
  @error "Breakpoint #{$key} is not defined.";
}

@function breakpoint-max($key) {
  @if (map-has-key($max-widths, $key)) {
    @return map-get($max-widths, $key);
  }
  @error "Breakpoint #{$key} is not defined.";
}

@mixin breakpoint($breakpoints...) {
  @each $b in $breakpoints {
    @media screen and (min-width: breakpoint-min($b)) and (max-width: breakpoint-max($b)) {
      @content;
    }
  }
}
