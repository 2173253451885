@font-face {
  font-family: "Gotham";
  src: url("Gotham-Book.woff2") format("woff2"),
    url("Gotham-Book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Medium.woff2") format("woff2"),
    url("Gotham-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Bold.woff2") format("woff2"),
    url("Gotham-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
