@import "../fonts/fonts.css";
@import "variables";
@import "breakpoints";
@import "mixins";

html {
  font: 400 18px/1.2 Gotham, sans-serif;

  @include breakpoint(laptop) {
    font-size: 16px;
  }

  @include breakpoint(tablet) {
    font-size: 14px;
  }

  @include breakpoint(mobile) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

.button {
  @include button;
}

select {
  @include input;
  padding-right: 2.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ee1100' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-down'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cpolyline points='19 12 12 19 5 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 0.5em;
  background-size: 1.5em 1.5em;
}
