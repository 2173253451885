@mixin button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1em;
  padding: 0.8em 1.2em;
  font: 400 1rem/1.2 Gotham, sans-serif;
  background: var(--color-primary);
  color: var(--color-white);
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-right: 0.8em;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
  }

  &.secondary {
    background: var(--color-white);
    color: var(--color-primary);
  }

  &.bare {
    border: 1px solid var(--color-gray);
    background: transparent;
    color: var(--color-text);

    &:hover {
      border-color: var(--color-primary);
    }
  }

  &:disabled {
    background: var(--color-light-gray);
    cursor: not-allowed;
  }
}

@mixin input {
  appearance: none;
  border: 1px solid var(--color-gray);
  border-radius: 1em;
  padding: 0.8em 1.2em;
  font: 400 1rem/1.2 Gotham, sans-serif;
  background: var(--color-white);
  color: var(--color-text);
}