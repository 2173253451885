@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/breakpoints";

.gate {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding: 1.5rem 2rem;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;

    .logo {
      height: 3rem;
      width: auto;
    }
  
    .title {
      font-size: 2rem;
      font-weight: 300;
    }

    .links {
      margin-top: 5vh;

      .button {
        margin: 0 1em;

        .icon {
          margin: 0 -0.5em 0 2em;
        }
      }
    }
  }

  .disclaimer {
    font-size: 0.8rem;
  }
}
